import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {addNewSubcategory} from '../Api'


const AddNewSubcategory = (props)=>{
    const [nameEn, setNameEn]= useState(0)
    const [nameAr, setNameAr]= useState(0)
    const [descriptionEn, setDescriptionEn]= useState(0)
    const [descriptionAr, setDescriptionAr]= useState(0)
    const [parentId, setParentId]= useState(0)
    const [image, setImage] = useState()
    const [priority, setPriority] = useState(1)

    const [valid, setValid] =useState(false)
    useEffect(()=>{
        // validations
        setValid(
            nameEn!='' && 
            nameAr!='' &&
            parentId>0 
            
        )
    },[nameEn, nameAr,parentId])
    function submit(){
        let subcategory=new FormData()
        subcategory.append('name_en', nameEn)
        subcategory.append('name_ar', nameAr )
        subcategory.append('description_en', descriptionEn)
        subcategory.append('description_ar', descriptionAr )
        subcategory.append('parent_id' , parentId)
        subcategory.append('priority' , priority>0? priority:1)

        subcategory.append('image',image)
        addNewSubcategory(subcategory).then(res=>{
            // console.log(res.status)
            if(res.status==='success'){
                props.displayMessage(res.status,'Category added successfully.')
                props.refreshSubcategories('refresh'+Math.random())
            }
            else{
                // failure
                props.displayMessage(res.status,'Something went wrong. couldn\'t add item.')
            }
        })
    }

    return(
        <Form>
           
            <Row>
                <Col>
                {/* English name */}
                    <FloatingLabel
                        controlId="name_en"
                        label="Category name in English"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setNameEn(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
                {/* Arabic name  */}
                <Col>
                    <FloatingLabel
                        controlId="name_ar"
                        label="اسم الكاتيجوري بالعربية"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setNameAr(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* English name */}
                    <FloatingLabel
                        controlId="name_en"
                        label="Category description in English"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setDescriptionEn(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
                {/* Arabic name  */}
                <Col>
                    <FloatingLabel
                        controlId="name_ar"
                        label="تعريف الكاتيجوري بالعربية"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setDescriptionAr(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            {/* Category */}
            <Row>
                <Col>
                    <FloatingLabel
                    controlId="Category"
                    label="Parent"
                    className="mb-3"
                >
                    <Form.Select aria-label="Default select example" onChange={(e)=>{ 
                        setParentId(e.target.value)}}
                        value={undefined}>
                        <option>Choose a category</option>

                    {props.categories.map(cat=>
                        <option value={cat.id} key={cat.id}>{cat.name_en}</option>
                        )}
                        
                    </Form.Select>
                </FloatingLabel>
                </Col>
                <Col sm={2}>
                    <FloatingLabel
                        controlId="order"
                        label="Order"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="number" onChange={(e)=>{setPriority(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            
          
          
            
            <Row>
                <Form.Group controlId="imageFile" className="mb-3">
                    <Form.Label>Upload image</Form.Label>
                    <Form.Control type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
                </Form.Group>
            </Row>
            <Button variant="primary" size='sm' onClick={submit} disabled={!valid}>
                Save Changes
            </Button>
        </Form>
    )
}
export default AddNewSubcategory