import React, { useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { useHistory } from "react-router-dom";

import { FaPhone } from "react-icons/fa"
import { FaLocationArrow } from "react-icons/fa"
import { FaCalendarCheck } from "react-icons/fa"
import { useReactToPrint } from 'react-to-print';
import EditUserModal from './EditUserModal'
import { changeOrderStatus} from '../Api'
import {formatDate, formatTime} from '../utilities'
import {getCustomers} from '../Api'
const Users = (props)=>{
    const history = useHistory();
    const [customers, setCustomers] = useState([])
    const [showEditUserModal, setShowEditUserModal] = useState(false)
    const [clickedUser, setClickedUser] = useState()
    const [refreshUsersTable, setRefreshUsersTable] =useState('')
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    useEffect(()=>{
        getCustomers().then(res=>{
            
            if(res.status=="success")
                setCustomers(res.customers)
            else{
                console.log('fetching customers failed.')
                localStorage.removeItem('user_token')
                history.push("/");
            }
                
        })
    },[])
    useEffect(()=>{
        if(alertMessageStatus==='success'){
            // close the modal on success
            setShowEditUserModal(false)
        }
    },[alertMessageStatus])
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    useEffect(()=>{
        getCustomers().then(res=>{
            setCustomers(res.customers)
        })
    },[refreshUsersTable])
    return(<Row>
         {/* header */}
         {alertMessageDisplay===true &&
                <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
                    style={{
                        position:'fixed',
                        top: '0px',
                        left: '35%', 
                        zIndex:9999, 
                        borderRadius:'0px',
                        width:'30%',
                        textAlign:'center'
                    }}
                    onClose={() => setAlertMessageDisplay(false)}>
                    <p>{alertMessageText}</p>
                </Alert>
            }
        <Col >
            <h4 style={{textAlign:'center', margin:'25px'}}> Customers List</h4>
            <Table striped bordered hover style={{width:'90%',marginLeft:'5%'}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ID </th>
                        <th> <FaPhone/> Mobile Number</th>
                        <th>  Name</th>
                        <th>  Email</th>

                        <th> <FaLocationArrow/> Address</th>
                        <th></th>
                    </tr>
                </thead>
                {customers &&
                <tbody>
                {customers.map((customer,index)=>
                    <tr key={customer.id}>
                    <td>{index+1}</td>
                    <td>{customer.id}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.name ===null? '-': customer.name}</td>
                    <td>{customer.email ===null? '-': customer.email}</td>
                    <td>{customer.address ===null? '-': customer.address}</td>
                    <td><Button size="sm" style={{width:'80%', marginLeft:'10%'}}
                    onClick={()=>{
                        setClickedUser(customer)
                        setShowEditUserModal(true)
                    }}
                    >Edit</Button></td>
                </tr>
                )}
                
                    
                    
                </tbody>
                }
            </Table>
            <Modal show={showEditUserModal} onHide={()=>{setShowEditUserModal(false)}} size="lg">
                <Modal.Header closeButton>
                <Modal.Title >Edit user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditUserModal 
                        userDetails={clickedUser}
                        refreshUsers={setRefreshUsersTable}
                        displayMessage={displayMessage}
                    ></EditUserModal>
                </Modal.Body>
                
                
            </Modal>
        </Col>
    </Row>)
}
export default Users
