import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FaTrashAlt } from "react-icons/fa"
import {updateSubcategory, deleteSubcategory} from '../Api'
import noImage from '../assets/images/no-image.png'; 
import {fileToDataUri} from '../utilities'
import Modal from 'react-bootstrap/Modal'


const Subcategory = (props)=>{
    const [nameEn, setNameEn]= useState(0)
    const [nameAr, setNameAr]= useState(0)
    const [descriptionEn, setDescriptionEn]= useState(0)
    const [descriptionAr, setDescriptionAr]= useState(0)
    const [parentId, setParentId]= useState(0)
    const [priority, setPriority] = useState(0)
    const [image, setImage] = useState()
    const [imageChanged, setImageChanged] = useState(false)
    const [imageUri, setImageUri] = useState('')
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false)

    const [valid, setValid] =useState(false)
    useEffect(()=>{
        setNameEn(props.data.name_en)
        setNameAr(props.data.name_ar)
        setDescriptionEn(props.data.name_en)
        setDescriptionAr(props.data.name_ar)
        setPriority(props.data.priority? props.data.priority:1)
        // setParentId(props.categories.filter(cat=> cat.id==props.data.parent_id)[0].id)
        setParentId(props.data.parent_id)
        
    },[])
    useEffect(()=>{
        // validations
        setValid(
            nameEn!='' && 
            nameAr!='' &&
            priority>0 &&
            parentId>0 
        )
    },[nameEn, nameAr,parentId, priority])
    function saveChanges(){
        let subcategory = new FormData()
        subcategory.append('id', props.data.id)
        subcategory.append('name_en', nameEn)
        subcategory.append('name_ar', nameAr)
        subcategory.append('description_en', descriptionEn)
        subcategory.append('description_ar', descriptionAr)
        subcategory.append('parent_id', parentId)
        subcategory.append('priority', priority)
        
        if(imageChanged)
            subcategory.append('image', image)

        updateSubcategory(subcategory).then(res=>{
            if(res.status==='success'){
                props.displayMessage(res.status,'Changes have been applied successfully.')
                props.refreshSubcategories('refresh'+Math.random())

            }
            else{
                // failure
                props.displayMessage(res.status,'Couldn\'y update item. Please contact support')

            }
        })
    }
    function deleteHandler(){
        deleteSubcategory(props.data.id).then(res=>{
            if(res.status==='success'){
                props.refreshSubcategories('refresh'+Math.random())
                props.displayMessage(res.status,'Subcategory deleted successfully.')
            }
            else{
                // failure
                props.displayMessage(res.status,'Couldn\'y delete item. Please contact support')
            }
            setShowDeleteCategoryModal(false)
        })
    }
    const onImageUpload = (file) => {
        if(!file) {
            setImageUri('');
            return;
        }
        setImage(file)
        setImageChanged(true)

        fileToDataUri(file).then(uri => {
            setImageUri(uri)
        })
    }
    return(
        <Col style={{padding:'10px'}}>
              <Modal show={showDeleteCategoryModal} onHide={()=>{setShowDeleteCategoryModal(false)}} size="sm">
                <Modal.Header closeButton>
                <Modal.Title >Confirm deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{padding:'10px'}}>
                        Are you sure you want to delete {props.data.name_en}?
                    </Row>
                   <Row>

                        
                        <Col style={{float:'right'}}>
                            <Button variant="secondary" size='sm' onClick={()=>setShowDeleteCategoryModal(false)} style={{margin:'2px'}}>
                                Cancel
                            </Button>
                        
                            <Button variant="danger" size='sm' onClick={ deleteHandler} style={{margin:'10px'}}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                
                
            </Modal>
            <Card style={{padding:'10px'}}>
                <Row>
                    <Col>
                        {/* English name */}
                        <p style={{fontSize:'10px', margin:'2px'}}>Subcategory Name In English</p>

                        <Form.Control size="sm" type="text" value={nameEn} onChange={(e)=>{setNameEn(e.target.value)}}/>
                        {/* Arabic name  */}
                        <p style={{fontSize:'10px', margin:'2px'}}>Subcategory Name In Arabic</p>

                        <Form.Control size="sm" type="text" value={nameAr} onChange={(e)=>{setNameAr(e.target.value)}}/>
                        {/* English description */}
                        <p style={{fontSize:'10px', margin:'2px'}}>Subcategory Description In English</p>

                        <Form.Control size="sm" type="text" value={descriptionEn} onChange={(e)=>{setDescriptionEn(e.target.value)}}/>
                        
                       </Col>
                    <Col>
                        <img className="item-image" src={imageChanged? imageUri : process.env.REACT_APP_APP_KEY+'/storage/images/'+props.data.image_url}
                            onError={(e)=>{e.target.onError = null; e.target.src = noImage}} 
                        ></img>
                       
                        <Form.Group controlId="imageFile" className="m-3">
                            <Form.Control type="file" size="sm" onChange={(e)=> onImageUpload(e.target.files[0]) }/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Arabic description  */}
                        <p style={{fontSize:'10px', margin:'2px'}}>Subcategory Description In Arabic</p>
                        <Form.Control size="sm" type="text" value={descriptionAr} onChange={(e)=>{setDescriptionAr(e.target.value)}}/>    

                    </Col>
                    <Col>
                        <p style={{fontSize:'10px', margin:'2px'}}>Order</p>
                        <Form.Control style={{width:'90%',margin:'5px'}} size="sm" placeholder="category order" type="text" value={priority} onChange={(e)=>{setPriority(e.target.value)}}/>

                    </Col>
                </Row>
                {/* Category */}
                <Row>
                    <Col>
                        <p style={{fontSize:'10px', margin:'2px'}}>Parent Category</p>

                        <Form.Select aria-label="Default select example" onChange={(e)=>{ 
                            setParentId(e.target.value)}}
                            value={parentId}>
                            <option>Choose a parent category</option>

                        {props.categories.map(cat=>
                            <option value={cat.id} key={cat.id}>{cat.name_en}</option>
                            )}
                            
                        </Form.Select>
                    </Col>
                    <Col>
                        <div style={{float:'right', display:'flex', marginTop:'20px'}}>
                            <Button variant="success" size='sm' onClick={saveChanges} disabled={!valid} 
                            style={{margin:'2px',paddingLeft:'20px',paddingRight:'20px'}}>
                                Save
                            </Button>
                        
                            <Button variant="danger" size='sm' onClick={()=>{setShowDeleteCategoryModal(true)}} style={{margin:'2px'}}>
                                <FaTrashAlt></FaTrashAlt>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Col>
    )
}
export default Subcategory