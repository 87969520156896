import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Card from 'react-bootstrap/Card'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Button from 'react-bootstrap/Button'
import { FaTrashAlt } from "react-icons/fa"
import { FaCheck } from "react-icons/fa"

import {updateItem, deleteItem} from '../Api'
import noImage from '../assets/images/no-image.png'; 
import {fileToDataUri} from '../utilities'
import Modal from 'react-bootstrap/Modal'

const Item =React.forwardRef((props, ref)=>{
    const [nameEn, setNameEn]= useState(0)
    const [nameAr, setNameAr]= useState(0)
    const [available, setAvailable]= useState(false)
    const [categoryId, setCategoryId]= useState(0)
    const [soldBy, setSoldBy]= useState(0)
    const [weight, setWeight]= useState(500)
    const [price, setPrice]= useState(0)
    const [hasDiscount, setHasDiscount]= useState(0)
    const [priceAfter, setPriceAfter]= useState(0)
    const [delivers, setDelivers]= useState('now')
    const [deliversOn, setDeliversOn]= useState(0)
    const [deliversWithin, setDeliversWithin]= useState(0)
    const [priority, setPriority] = useState(0)
    const [image, setImage] = useState()
    const [imageChanged, setImageChanged] = useState(false)
    const [imageUri, setImageUri] = useState('')
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
    const [valid, setValid] =useState(false)
    useEffect(()=>{
        // console.log(props.data)
        setNameEn(props.data.name_en)
        setNameAr(props.data.name_ar)
        setAvailable(props.data.available)
        if(props.mode!='sort')
            setCategoryId(props.subcategories.filter(sub=> sub.id==props.data.category_id)[0].id)
        setSoldBy(props.data.sold_by=='weight'?1:0)
        setWeight(props.data.unit_weight_in_grams)
        setPrice(props.data.price)
        setHasDiscount(props.data.has_active_discount)
        setPriceAfter(props.data.price_after_discount)
        setDelivers(props.data.delivery_status)
        setDeliversOn(props.data.delivers_on)
        setDeliversWithin(props.data.delivers_within)
        setPriority(props.data.priority? props.data.priority:1)

    },[])
    useEffect(()=>{
        // validations
        setValid(
            nameEn!='' && 
            nameAr!='' &&
            categoryId>0 &&
            (soldBy==0 || (soldBy==1 && weight>0) ) &&
            price!='' &&
            (priority !=="" && priority>0)&&
            (hasDiscount == false || (hasDiscount==true &&priceAfter>0)) &&
            (delivers =='now' || (delivers=='on' && deliversOn!='') || (delivers=='within' &&deliversWithin>0))
        )
    },[nameEn, nameAr,categoryId,soldBy,weight,price,priceAfter,hasDiscount, delivers,deliversOn,deliversWithin, priority])
    function saveChanges(){
        let item=new FormData()
        item.append('id', props.data.id)
        item.append('name_en', nameEn)
        item.append('name_ar', nameAr )
        item.append('category_id' , categoryId)
        item.append('available', available)
        item.append('sold_by', soldBy==1? 'weight':'unit')
        item.append('unit_weight_in_grams', weight)
        item.append('price' , price)
        item.append('price_after_discount' , priceAfter)
        item.append('has_discount' , hasDiscount?1:0)
        item.append('delivery_status',delivers)
        item.append('delivers_on',deliversOn)
        item.append('delivers_within' , deliversWithin)
        item.append('priority', priority)
        item.append('available', available?1:0)
        if(imageChanged)
            item.append('image',image)
        updateItem(item).then(res=>{
            if(res.status==='success'){
                props.displayMessage(res.status,'Changes have been applied successfully.')
                props.refreshItems('refresh'+Math.random())

            }
            else{
                // failure
                props.displayMessage(res.status,'Couldn\'y update item. Please contact support')

            }
        })
    }
    function deleteHandler(){
        deleteItem(props.data.id).then(res=>{
            // console.log(res)
            if(res.status==='success'){
                props.refreshItems('refresh'+Math.random())
                props.displayMessage(res.status,'Item deleted successfully.')
            }
            else{
                // failure
                props.displayMessage(res.status,'Couldn\'y delete item. Please contact support')
            }
        })
    }
    
    const onImageUpload = (file) => {
        // console.log('file uploaded')
        if(!file) {
            setImageUri('');
            return;
        }
        setImage(file)
        setImageChanged(true)

        fileToDataUri(file).then(uri => {
            setImageUri(uri)
        })
    }
    return(
        <Col style={{padding:'10px', cursor: props.mode=='sort'?'move':'auto'}}>
            <Modal show={showDeleteItemModal} onHide={()=>{setShowDeleteItemModal(false)}} size="sm">
                <Modal.Header closeButton>
                <Modal.Title >Confirm deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{padding:'10px'}}>
                        Are you sure you want to delete {props.data.name_en}?
                    </Row>
                   <Row>

                        
                        <Col style={{float:'right'}}>
                            <Button variant="secondary" size='sm' onClick={()=>setShowDeleteItemModal(false)} style={{margin:'2px'}}>
                                Cancel
                            </Button>
                        
                            <Button variant="danger" size='sm' onClick={ deleteHandler} style={{margin:'10px'}}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                
                
            </Modal>
            <Card style={{
                padding:'10px', 
                opacity:available?1:0.4,
                border: props.mode=='sort'? '2px dashed blue':'',
                pointerEvents:props.mode=='sort'? 'none':''
                }} ref={ref}>
                <Row>
                    <Col>
                        {/* English name */}
                        <p style={{fontSize:'10px', margin:'2px'}}>English Name</p>

                            <Form.Control style={{margin:'5px'}} size="sm" placeholder="Item name in English" type="text" value={nameEn} onChange={(e)=>{setNameEn(e.target.value)}}/>
                        {/* Arabic name  */}
                        <p style={{fontSize:'10px', margin:'2px'}}>Arabic Name</p>

                            <Form.Control style={{margin:'5px'}} size="sm" placeholder="Item name in Arabic" type="text" value={nameAr} onChange={(e)=>{setNameAr(e.target.value)}}/>
                        {props.mode!="sort"&& 
                        <React.Fragment>
                        {/* Category */}
                            <p style={{fontSize:'10px', margin:'2px'}}>Category</p>
                            <Form.Select onChange={(e)=>{
                                setCategoryId(e.target.value)}}
                                id="category_selection"
                                value={categoryId}>
                                <option>Choose a category</option>

                            {props.subcategories.map(sub=>
                                <option value={sub.id} key={sub.id}>{sub.name_en}</option>
                                )}
                                
                            </Form.Select>
                            </React.Fragment>
                        }
                    </Col>
                    <Col>                        

                        <img className="item-image" src={imageChanged? imageUri : process.env.REACT_APP_APP_KEY+'/storage/images/'+props.data.image_url}
                            onError={(e)=>{e.target.onError = null; e.target.src = noImage}} 
                        ></img>
                        {props.mode!="sort"&& 
                        <Form.Control style={{width:'80%',margin:'5px', marginLeft:'10%'}} label="select"  size= "sm" type="file" onChange={(e)=> onImageUpload(e.target.files[0]) }/>
                        }
                    </Col>
                
                </Row>
                {props.mode!="sort"&& 
                <React.Fragment>
                    <Row>
                        {/* Sold by */}
                        <Col sm={6}>
                            <p style={{fontSize:'10px', margin:'2px'}}>Sold By</p>

                            <Form.Select 
                                value={soldBy}
                                onChange={(e)=>{setSoldBy(e.target.value)}}>
                                <option value={0}>Unit</option>
                                <option value={1}>Weight</option>
                            </Form.Select>
                        </Col>
                        {/* Weight */}
                        {soldBy ==1 &&
                        <Col>
                            <p style={{fontSize:'10px', margin:'2px'}}>Unit Weight</p>
                            <Form.Control style={{width:'90%',margin:'5px'}} size="sm" type="text" value={weight} onChange={(e)=>{setWeight(e.target.value)}}/>
                        </Col>
                        }
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <p style={{fontSize:'10px', margin:'2px'}}>Price</p>
                            <Form.Control style={{margin:'5px'}} size="sm" type="text" value={price} onChange={(e)=>{setPrice(e.target.value)}}/>
                            
                        </Col>
                        {/* has discount */}
                        {hasDiscount==true&&
                        <Col>
                                <p style={{fontSize:'10px', margin:'2px'}}>After Discount</p>

                                <Form.Control style={{width:'90%',margin:'5px'}} size="sm" type="text" value={priceAfter} onChange={(e)=>{setPriceAfter(e.target.value)}} />
                        </Col>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId={'has_discount_'+props.data.id}>
                                <Form.Check type="checkbox" label="Apply discount" checked={hasDiscount} onChange={(e)=>{ console.log(e); setHasDiscount(e.target.checked)}}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <p style={{fontSize:'10px', margin:'2px'}}>Order</p>
                            <Form.Control style={{width:'90%',margin:'5px'}} size="sm" placeholder="Item order" type="number" value={priority} onChange={(e)=>{setPriority(e.target.value)}}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {/* Delivers */}
                            <p style={{fontSize:'10px', margin:'2px'}}>Delivers</p>

                                <Form.Select aria-label="Default select example" value={delivers} onChange={(e)=>{setDelivers(e.target.value)}}>
                                    <option value="now">Now</option>
                                    <option value="within">Within</option>
                                    <option value="on">On</option>
                                </Form.Select>
                        </Col>
                        {/* delivers on */}
                        {delivers=='on'&&
                        <Col>
                            
                                <Form.Select aria-label="Default select example" 
                                    value={deliversOn}
                                    onChange={(e)=>{setDeliversOn(e.target.value)}}>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                            

                                </Form.Select>
                        </Col>
                        }
                        {delivers=='within'&&
                        <Col>
                    
                            <Form.Control size="sm" type="text" 
                            value={deliversWithin} 
                            onChange={(e)=>{setDeliversWithin(e.target.value)}} />
                        </Col>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <div style={{float:'right', display:'flex'}}>
                                <ToggleButton
                                    id={'toggle_'+props.data.id}
                                    type="checkbox"
                                    variant={available?"primary":'secondary'}
                                    checked={available}
                                    value="1"
                                    onChange={(e) => {
                                        setAvailable(e.currentTarget.checked)
                                    }}
                                >
                                    {available?<FaCheck/>:"-"}
                                </ToggleButton>
                                <Button variant="success" size='sm' onClick={saveChanges} disabled={!valid} 
                                style={{margin:'2px',paddingLeft:'20px',paddingRight:'20px'}}>
                                    Save
                                </Button>

                            

                                <Button variant="danger" size='sm' onClick={()=> setShowDeleteItemModal(true)} style={{margin:'2px'}}>
                                    <FaTrashAlt></FaTrashAlt>
                                </Button>
                            </div>
                        </Col>
                    
                    </Row>
                </React.Fragment>
                }
            </Card>
        </Col>
    )
})
export default Item