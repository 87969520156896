import React, {useState, useEffect} from 'react'

import Card from 'react-bootstrap/Card'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {updateItemAvailability} from '../Api'
const AvailabilityItem = ({item,displayMessage})=>{
    const [available,setAvailable]=useState(true)
    useEffect(()=>{
        setAvailable(item.available)
    },[])
    function updateAvailability(availability){
        updateItemAvailability(item.id,availability?1:0).then(res=>{
            // console.log(res)
            if(res.status==='success'){
                displayMessage(res.status,'Availabilty updated successfully.')

            }
            else{
                // failure
                displayMessage(res.status,'Couldn\'t update availability. Please contact support.')

            }
        })
    }
    return(<div>
        <Card body style={{padding:'10px', margin:'10px'}}>
            <Row>
            <Col sm={7}>{item.name_en} - {item.name_ar}</Col>
            <Col sm={5}> 
                <ButtonGroup className="mb-2" size="sm">

                    <ToggleButton
                        id={'toggle_'+item.id}
                        type="checkbox"
                        variant={available?"success":'secondary'}
                        checked={available}
                        value="1"
                        onChange={(e) => {
                            setAvailable(e.currentTarget.checked)
                            updateAvailability(e.currentTarget.checked)
                        }}
                    >
                    {available?'Available':'Unavailable'}
                    </ToggleButton>

            </ButtonGroup>
        </Col>                 
    </Row>
        </Card>
    </div>)
}
export default AvailabilityItem
