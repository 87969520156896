import React, {useState, useEffect, useRef, useMemo} from 'react'
import debounce from 'lodash.debounce';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Alert from 'react-bootstrap/Alert'

import { FaSearch } from "react-icons/fa"
import { useHistory } from "react-router-dom";

import AvailabilityItem from './AvailabilityItem'
import {getAllSubcategories, getCategoryItems,adminSearch} from '../Api'

const AvailabilityManager = ()=>{
    const history = useHistory();
    const [subcategories,setSubcategories] = useState([])
    const [ready, setReady] =useState(false)
    const [selectedCategoryId, setSelectedCategoryId] = useState()
    const [items, setItems]= useState([])
    const [searchString, setSearchString] = useState('')
    const [refreshCategoryItems, setRefreshCategoryItems] =useState('')
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    const availabilitySearchInputRef= useRef('')

    useEffect(()=>{
        getAllSubcategories().then(response=>{
            if(response.status==='failure'){
                localStorage.removeItem('user_token')
                history.push("/");
            }else{
                setSubcategories(response.data)
                setReady(true)
            }  
        })
        return () => {
            debouncedResults.cancel();
          };
    },[])
    
    useEffect(()=>{
        if(selectedCategoryId){
            availabilitySearchInputRef.current.value=""
            getCategoryItems(selectedCategoryId).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        }
    },[selectedCategoryId])
    useEffect(()=>{
        if(selectedCategoryId){
            getCategoryItems(selectedCategoryId).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        }
    },[refreshCategoryItems])
    useEffect(()=>{
        if(searchString.length){
            adminSearch(searchString).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        } else{
            // go back to state before search .. subcategory items 
            // only if a subcategory is selected
            if(selectedCategoryId){
                setRefreshCategoryItems('refresh'+Math.random())
            } else{
                // just clear items array
                setItems([])
            }
           
        }
    },[searchString])
    const handleSearchStringChange=(e)=>{
        setSearchString(e.target.value)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearchStringChange, 300);
      }, [])
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    return(<div>
        {ready && 
        <div>
        {alertMessageDisplay===true &&
        <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
            style={{
                position:'fixed',
                top: '0px',
                left: '15%', 
                width: '100%',
                zIndex:9999, 
                borderRadius:'0px',
                width:'70%',
            }}
            onClose={() => setAlertMessageDisplay(false)}>
            <p>{alertMessageText}</p>
        </Alert>
    }
         <Row style={{padding:'20px', borderBottom:'1px solid #f0f2f0', backgroundColor:'#edf0ef', paddingRight:'40px'}} >
            <Col sm={2} style={{display:'flex',alignItems:'center',justifyContent:'left'}}>
                <h6> Availability Manager </h6>
            </Col>
            <Col sm={3}>
                <FloatingLabel
                    controlId="Category"
                    label="Category"
                    className="mb-3"
                >
                    {subcategories &&
                    <Form.Select aria-label="Default select example" onChange={(e)=>{ console.log(e.target.value)
                        setSelectedCategoryId(e.target.value)}}
                        value={undefined}>
                        <option>Choose a category</option>
                        
                    {subcategories.map(sub=>
                        <option value={sub.id} key={sub.id}>{sub.name_en}</option>
                        )}
                        
                    </Form.Select>
                    }
                </FloatingLabel>
            </Col>

            <Col sm={3} style={{display:'flex',alignItems:'baseline',justifyContent:'left'}}>
                    <InputGroup   size="sm">
                        <InputGroup.Text id="basic-addon1"><FaSearch/></InputGroup.Text>
                        <input
                        placeholder="Search items"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={debouncedResults}
                        ref={availabilitySearchInputRef}
                        style={{padding:'5px', border:'1px solid lightgray'}}
                        />
                    </InputGroup>
                </Col>
            
        </Row>
        <div className=" d-flex flex-wrap">
            {items.map((item,idx)=>{
                return <Col sm={3} key={idx}>
                    <AvailabilityItem key={item.id}
                        item={item} 
                        displayMessage={displayMessage}
                    ></AvailabilityItem>
                </Col>
            })}
        </div>
        </div>
        }
    </div>)
}
export default AvailabilityManager