import React, {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert'
import { useHistory } from "react-router-dom";

import {login} from '../Api'

const Login = ()=>{
    const [email,setEmail]= useState('')
    const [password,setPassword]= useState('')
    const [status,setStatus] =useState('')
    const [notifyMessage,setNotifyMessage] =useState('')

    // history to redirect after login
    const history = useHistory();
    useEffect(()=>{
        if(localStorage.getItem('user_token')!=null){
            console.log('redirected')
            history.push("/dashboard");
        }
    },[])
    function handleLogin(){
        let data={
            email:email,
            password:password
        }
        login(data).then((res)=>{
            setStatus(res.status)
            if(res.status==='success'){
                history.push("/dashboard");
            } else{
                setNotifyMessage(res.error)
            }
        })
    }
    function handleKeyPress(e){
        if(e.key==='Enter') handleLogin()
    }
    return(
<div className='container p-10 login-container' style={{width:"50%"}}>
    {status==='failure'&&
    <Alert variant="danger" onClose={() => setStatus('')} dismissible>
        <p>{notifyMessage}</p>
    </Alert>
    }
    <Alert variant='warning' className='center'>
        <p style={{textDecoration:'underline'}}>Warning!</p>
        <p>This is a private portal for <b>Tawsi2a</b> employees only. You can download the app at apple store or at Google Play </p>
    </Alert>
            
    <Card className="center" style={{width:'70%',marginLeft:'15%'}} onKeyPress={handleKeyPress}>
        <Card.Body>
            <Form>
                <h4>Login</h4>
                <Form.Group controlId="formBasicEmail" style={{margin:'5px'}}>
                    <Form.Control type="email" placeholder="Enter email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" style={{margin:'5px'}}>
                    <Form.Control type="password" placeholder="Password" 
                    value={password} 
                    onChange={e => setPassword(e.target.value)}
                    />
                </Form.Group>
                
                <Button variant="primary"  onClick={()=>{handleLogin()}}>
                    Submit
                </Button>
            </Form>
        </Card.Body>
    </Card>

</div>)}
export default Login