import React, { useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { FaTrashAlt } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import { useHistory } from "react-router-dom";



import DeliveryAreaModal from './DeliveryAreaModal'

import {getDeliveryAreas,deleteDeliveryArea} from '../Api'
const DeliveryAreas = (props)=>{
    const history = useHistory();
    const [deliveryAreas, setDeliveryAreas] = useState([])
    const [showDeliveryAreaModal, setShowDeliveryAreaModal] = useState(false)
    const [deliveryAreaModalMode, setDeliveryAreaModalMode] = useState('new')
    
    const [showDeleteDeliveryAreaModal, setShowDeleteDeliveryAreaModal] = useState(false)
    const [clickedDeliveryArea, setClickedDeliveryArea] = useState()
    const [refreshDeliveryAreasTable, setRefreshDeliveryAreasTable] =useState('')
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    useEffect(()=>{
        getDeliveryAreas().then(res=>{
            console.log(res)
            if(res.status=="success")
                setDeliveryAreas(res)
            else{
                console.log('fetching delivery areas failed.')
                // localStorage.removeItem('user_token')
                // history.push("/");
            }
                
        })
    },[])
    useEffect(()=>{
        if(alertMessageStatus==='success'){
            // close the modal on success
            setShowDeliveryAreaModal(false)
        }
    },[alertMessageStatus])
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    function deleteHandler(){
        deleteDeliveryArea(clickedDeliveryArea.id).then(res=>{
            // console.log(res)
            if(res.status==='success'){
                displayMessage(res.status,'Delivery area deleted successfully.')
                setShowDeleteDeliveryAreaModal(false)
                setRefreshDeliveryAreasTable('refresh'+Math.random())

            }
            else{
                // failure
                props.displayMessage(res.status,'Couldn\'y delete delivery area. Please contact support')
            }
        })
    }
    useEffect(()=>{
        getDeliveryAreas().then(res=>{
            setDeliveryAreas(res)
        })
    },[refreshDeliveryAreasTable])
    return(<Row>
         {/* header */}
         {alertMessageDisplay===true &&
                <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
                    style={{
                        position:'fixed',
                        top: '0px',
                        left: '35%', 
                        zIndex:9999, 
                        borderRadius:'0px',
                        width:'30%',
                        textAlign:'center'
                    }}
                    onClose={() => setAlertMessageDisplay(false)}>
                    <p>{alertMessageText}</p>
                </Alert>
            }
        <Col >
            <h4 style={{textAlign:'center', margin:'25px'}}> Delivery Areas List</h4>
            <Row>
                <Col>
                    <Table striped bordered hover style={{width:'90%',marginLeft:'5%'}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>  Name EN</th>
                            <th>  Name AR</th>
                            <th> Delivery Price</th>
                            <th></th>
                            <th> 
                                <Button variant="success" size="sm" 
                                style={{width:'60%', marginLeft:'20%'}}
                                onClick={()=>{
                                    setDeliveryAreaModalMode('new');
                                    setShowDeliveryAreaModal(true)}}>
                                    <FaPlus style={{fontSize:12}}/> Add New
                                </Button>
                            </th>
                        </tr>
                        </thead>
                        {deliveryAreas &&
                        <tbody>
                        {deliveryAreas.map((area,index)=>
                            <tr key={area.id}>
                            <td>{index+1}</td>
                            <td>{area.name_en ===null? '-': area.name_en}</td>
                            <td>{area.name_ar ===null? '-': area.name_ar}</td>
                            <td>{area.delivery_price ===null? '-': area.delivery_price} LE</td>
                            <td><Button size="sm" style={{width:'80%', marginLeft:'10%'}}
                            onClick={()=>{
                                setClickedDeliveryArea(area)
                                setDeliveryAreaModalMode('edit')
                                setShowDeliveryAreaModal(true)
                            }}
                            >Edit</Button></td>
                            <td><Button size="sm" variant='danger'
                            style={{width:'40%', marginLeft:'30%'}}
                            onClick={()=>{
                                setClickedDeliveryArea(area)
                                setShowDeleteDeliveryAreaModal(true)
                            }}
                            >  <FaTrashAlt></FaTrashAlt></Button></td>
                        </tr>
                        )}
                        
                            
                            
                        </tbody>
                        }
                    </Table>
                </Col>
            </Row>
            
            <Modal 
            show={showDeliveryAreaModal} 

            onHide={()=>{setShowDeliveryAreaModal(false)}} size="lg">
                <Modal.Header closeButton>
                <Modal.Title >{deliveryAreaModalMode=='edit'? 'Edit':'Create new '} Delivery Area</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeliveryAreaModal 
                        mode = {deliveryAreaModalMode}
                        areaDetails={clickedDeliveryArea}
                        refreshDeliveryAreas={setRefreshDeliveryAreasTable}
                        displayMessage={displayMessage}
                    ></DeliveryAreaModal>
                </Modal.Body>
                
                
            </Modal>
            <Modal show={showDeleteDeliveryAreaModal} onHide={()=>{setShowDeleteDeliveryAreaModal(false)}} size="sm">
                <Modal.Header closeButton>
                <Modal.Title >Confirm deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{padding:'10px'}}>
                        Are you sure you want to delete {clickedDeliveryArea? clickedDeliveryArea.name_en:''}?
                    </Row>
                   <Row>

                        
                        <Col style={{float:'right'}}>
                            <Button variant="secondary" size='sm' onClick={()=>setShowDeleteDeliveryAreaModal(false)} style={{margin:'2px'}}>
                                Cancel
                            </Button>
                        
                            <Button variant="danger" size='sm' onClick={ deleteHandler} style={{margin:'10px'}}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                
                
            </Modal>
        </Col>
    </Row>)
}
export default DeliveryAreas
