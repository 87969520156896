import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {addNewItem} from '../Api'


const AddNewItem = (props)=>{
    const [nameEn, setNameEn]= useState('')
    const [nameAr, setNameAr]= useState('')
    const [categoryId, setCategoryId]= useState(0)
    const [soldBy, setSoldBy]= useState(0)
    const [weight, setWeight]= useState(500)
    const [price, setPrice]= useState('')
    const [hasDiscount, setHasDiscount]= useState(false)
    const [priceAfter, setPriceAfter]= useState(0)
    const [delivers, setDelivers]= useState('now')
    const [deliversOn, setDeliversOn]= useState(0)
    const [deliversWithin, setDeliversWithin]= useState(0)
    const [priority, setPriority] = useState(1)
    const [image, setImage] = useState()
    const [valid, setValid] =useState(false)
    useEffect(()=>{
        // validations
        console.log(soldBy)
        console.log(soldBy==0 )
       
        setValid(
            nameEn!=='' && 
            nameAr!=='' &&
            categoryId>0 &&
            (soldBy==0 || (soldBy==1 && weight>0) ) &&
            price!=='' &&
            (priority!=="" && priority>0) &&
            (hasDiscount === false || (hasDiscount===true &&priceAfter>0)) &&
            (delivers ==='now' || (delivers==='on' && deliversOn!=='') || (delivers==='within' &&deliversWithin>0))
        )
    },[nameEn, nameAr,categoryId,soldBy,weight,price,priceAfter,hasDiscount, delivers,deliversOn,deliversWithin, priority])
    function submit(){
        let item=new FormData()
        item.append('name_en', nameEn)
        item.append('name_ar', nameAr )
        item.append('category_id' , categoryId)
        item.append('sold_by', soldBy==1? 'weight':'unit')
        item.append('unit_weight_in_grams', weight)
        item.append('price' , price)
        item.append('price_after_discount' , priceAfter)
        item.append('has_discount' , hasDiscount?1:0)
        item.append('delivery_status',delivers)
        item.append('delivers_on',deliversOn)
        item.append('delivers_within' , deliversWithin)
        item.append('priority', priority>0? priority:1)
        item.append('image',image)
        addNewItem(item).then(res=>{
            // console.log(res.status)
            if(res.status==='success'){
                props.displayMessage(res.status,'Item added successfully.')
                props.refreshItems('refresh'+Math.random())
            }
            else{
                // failure
                props.displayMessage(res.status,'Something went wrong. couldn\'t add item.')
            }
        })
    }

    return(
        <Form>
           
            <Row>
                <Col>
                {/* English name */}
                    <FloatingLabel
                        controlId="name_en"
                        label="Item name in English"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setNameEn(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
                {/* Arabic name  */}
                <Col>
                    <FloatingLabel
                        controlId="name_ar"
                        label="اسم السلعة بالعربية"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setNameAr(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
                <Col sm={2}>
                    <FloatingLabel
                        controlId="order"
                        label="Order"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="number" defaultValue={priority}  onChange={(e)=>{setPriority(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            {/* Category */}
            <FloatingLabel
                controlId="Category"
                label="Category"
                className="mb-3"
            >
                <Form.Select aria-label="Default select example" onChange={(e)=>{ 
                    setCategoryId(e.target.value)}}
                    value={undefined}>
                    <option>Choose a category</option>

                {props.subcategories.map(sub=>
                    <option value={sub.id} key={sub.id}>{sub.name_en}</option>
                    )}
                    
                </Form.Select>
            </FloatingLabel>
            <Row>
                {/* Sold by */}
                <Col sm={6}>
                    <FloatingLabel
                        controlId="soldBy"
                        label="Sold by"
                        className="mb-3"
                    >
                        <Form.Select aria-label="Default select example" onChange={(e)=>{setSoldBy(e.target.value)}}>
                            <option value={0}>Unit</option>
                            <option value={1}>Weight</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                {/* Weight */}
                {soldBy ==1 &&
                <Col>
                    <FloatingLabel
                        controlId="weight"
                        label="Minimum weight in grams"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" value={weight} onChange={(e)=>{setWeight(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
                }
            </Row>
            <Row>
                <Col sm={6}>
                    <FloatingLabel
                        controlId="price_before"
                        label="price"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text"  onChange={(e)=>{setPrice(e.target.value)}}/>
                    </FloatingLabel>
                    
                    <Form.Group className="mb-3" controlId="has_discount">
                        <Form.Check type="checkbox" label="Apply discount" onChange={(e)=>{ console.log(e); setHasDiscount(e.target.checked)}}/>
                    </Form.Group>
                </Col>
                {/* has discount */}
                {hasDiscount==true&&
                <Col>
                    <FloatingLabel
                        controlId="price_after"
                        label="Price after discount"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" onChange={(e)=>{setPriceAfter(e.target.value)}} />
                    </FloatingLabel>
                </Col>
                }
            </Row>
            <Row>
                <Col sm={6}>
                    <FloatingLabel
                        controlId="Delivers"
                        label="Delivers"
                        className="mb-3"
                    >
                        <Form.Select aria-label="Default select example" onChange={(e)=>{setDelivers(e.target.value)}}>
                            <option value="now">Now</option>
                            <option value="within">Within</option>
                            <option value="on">On</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                {/* delivers on */}
                {delivers=='on'&&
                <Col>
                    <FloatingLabel
                        controlId="deliversOn"
                        label="Select the day"
                        className="mb-3"
                    >
                        <Form.Select aria-label="Default select example" onChange={(e)=>{setDeliversOn(e.target.value)}}>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                    

                        </Form.Select>
                    </FloatingLabel>
                </Col>
                }
                {delivers=='within'&&
                <Col>
                    <FloatingLabel
                        controlId="deliversWithin"
                        label="Number of days"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" onChange={(e)=>{setDeliversWithin(e.target.value)}} />
                    </FloatingLabel>
                </Col>
                }
            </Row>
            <Row>
                <Form.Group controlId="imageFile" className="mb-3">
                    <Form.Label>Upload image</Form.Label>
                    <Form.Control type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
                </Form.Group>
            </Row>
            <Button variant="primary" size='sm' onClick={submit} disabled={!valid}>
                Save Changes
            </Button>
        </Form>
    )
}
export default AddNewItem