import React, {useState, useEffect, useRef} from 'react'
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'
import { useHistory } from "react-router-dom";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import { FaSort } from "react-icons/fa"
import { FaTimes } from "react-icons/fa"
import { FaCheck } from "react-icons/fa"
import Collapse from 'react-bootstrap/Collapse';
import Item from './Item'
import {getAllDeals, sortDeals} from '../Api'

const DealsManager = ()=>{
    const history = useHistory();
    const [ready,setReady] = useState(false)
    const [items,setItems] = useState(false)
    const [showDiscardConfirmationBox,setShowDiscardConfirmationBox] = useState(false)
    const [itemsBeforeSorting, setItemsBeforeSorting] = useState([])
    const refs= useRef([])

    
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    useEffect(()=>{
        getAllDeals().then(res=>{
            if(res.status=="failure"){
                console.log('fetching deals failed.')
                // localStorage.removeItem('user_token')
                // history.push("/");
            }
            else{
                setItems(res.data)
                setItemsBeforeSorting(res.data)
                setReady(true)
            }
            
        })
    },[])
    
    const onSortEnd = (oldIndex, newIndex) => {
        setItems((array) => arrayMove(array, oldIndex, newIndex));
      };
    function saveSorting(){
        
        let sortedItems=[]
        items.forEach(item=>{
            sortedItems.push(item.id)
        })
        sortDeals({items:sortedItems}).then(res=>{
            if(res.status=='success'){
                setAlertMessageStatus('success')
                setAlertMessageText('Sorting saved successfully.')
                setAlertMessageDisplay(true)
            } else{
                setAlertMessageStatus('danger')
                setAlertMessageText('Sorting saved failed. Something went wrong.')
                setAlertMessageDisplay(true)
            }
        })
    }
    function discardSortingChanges(){
        setItems(itemsBeforeSorting)
    }
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    return ready?
    (
        <div>
            {alertMessageDisplay===true &&
            <Collapse in={alertMessageDisplay} appear={true} >
                <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
                    style={{
                        position:'fixed',
                        top: '0px',
                        left: '15%', 
                        width: '100%',
                        zIndex:9999, 
                        borderRadius:'0px',
                        width:'70%',
                    }}
                    onClose={() => setAlertMessageDisplay(false)}>
                    <p>{alertMessageText}</p>
                </Alert>
            </Collapse>
            }

            <Row style={{padding:'20px', borderBottom:'1px solid #f0f2f0', backgroundColor:'#edf0ef', paddingRight:'40px'}}>
                <Col sm={8}> <b>Sort Deals</b></Col>
                <Col sm={4}>
                    <Button variant="success" size="sm" style={{minWidth:'60px',margin:'3px'}} onClick={()=>{saveSorting()}}>
                        <FaCheck style={{fontSize:12}}/> Save 
                    </Button>
                    <Button variant="danger" size="sm" style={{minWidth:'60px',margin:'3px'}} onClick={()=>{setShowDiscardConfirmationBox(true)}}>
                        <FaTimes style={{fontSize:12}}/> Discard
                    </Button>
                </Col>
            </Row>
            <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
            <div className=" d-flex flex-wrap">
            {items.map((item,idx) => (
                <SortableItem key={item.id} style="cursor: grab">
                    <Col sm={4}>
                        <Item 
                            ref={refs[idx]}
                            mode={'sort'}
                            data={item} 
                            displayMessage={displayMessage}
                        ></Item>
                    </Col>
                </SortableItem>
            ))}
                </div>
            </SortableList>
            {/* discard sorting changes confirmation modal */}
            <Modal show={showDiscardConfirmationBox} onHide={()=>{setShowDiscardConfirmationBox(false)}} size="md">
                <Modal.Header closeButton>
                <Modal.Title >Discard sorting changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to discard sorting changes? All changes will be reverted.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> setShowDiscardConfirmationBox(false)}>Cancel</Button>
                    <Button variant="danger" onClick={()=>{
                        discardSortingChanges();
                        setShowDiscardConfirmationBox(false)
                    }}>Discard</Button>
                </Modal.Footer>
                
            </Modal>
            </div>
    ): <div>
        fetching deals
    </div>
}
export default DealsManager