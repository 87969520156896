import React, { useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Analysis = (props)=>{
    
    
    return(<Row>
        <Col >
            <h1 style={{textAlign:'center',fontSize:'90px', marginTop:'150px'}}> COMING  SOON</h1>
            
        </Col>
    </Row>)
}
export default Analysis
