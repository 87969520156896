import React, { useRef} from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { FaPhone } from "react-icons/fa"
import { FaLocationArrow } from "react-icons/fa"
import { FaCalendarCheck } from "react-icons/fa"
import { useReactToPrint } from 'react-to-print';

import { changeOrderStatus} from '../Api'
import {formatDate, formatTime} from '../utilities'

const Order = ({index,order,confirmDeletion,orderToDeleteId,successNotify,refreshDisplaying})=>{
    const orderRef= useRef('order_'+order.id)
    const handlePrint= useReactToPrint({
        content: () => orderRef.current,
    });
    
    function updateOrderStatus(orderId,status){
        changeOrderStatus(orderId,status).then(response=>{
            //validate failure
            // show success alert
            if(response.status=="success"){
                successNotify(true)
                refreshDisplaying('refresh'+Math.random())
                console.log('no error')
            }
           
        })
    }
    return(
        <div style={{position:'relative'}}>
            <div className='orderIndex'>{index+1}.</div>
            <Card border={order.status==='pending'? 'warning'
                
                :order.status==='outForDelivery'?'primary'
                :'success'} 
                ref={orderRef}
                style={{ margin: '5px', width:'90%',marginLeft:'5%', borderWidth:'3px'}} key={order.id}
            >
                <Card.Header>
                    <Row>
                        <Col sm={4}>
                            <b>Order ID #</b>{order.id}
                        </Col>
                        <Col>
                            <FaCalendarCheck/> {formatDate(order.created_at)} {formatTime(order.created_at)}
                        </Col>
                        <Col >
                            {(localStorage.getItem('admin_type') =='superadmin' && order.status!=='cancelled'  && order.status!=='completed') &&
                            <Button variant='outline-danger' size='sm' style={{float:'right', marginRight:'5px'}}
                                onClick={()=>{
                                    orderToDeleteId(order.id)
                                    confirmDeletion(true)
                                }}
                            >Cancel</Button>
                            }
                            <Button 
                                variant='secondary' size='sm' 
                                onClick={handlePrint}
                                style={{float:'right', marginRight:'5px'}}>
                                    Print
                            </Button>
                            {(order.status=='outForDelivery' || order.status=='pending' )&&
                            <Button 
                                variant='outline-success' size='sm' style={{float:'right', marginRight:'5px'}}
                                onClick ={()=>{updateOrderStatus(order.id,'completed')}}
                            >Complete</Button>
                            }
                            {order.status==='pending' &&
                            <Button 
                                variant='outline-primary' size='sm' style={{float:'right', marginRight:'5px'}}
                                onClick ={()=>{updateOrderStatus(order.id,'outForDelivery')}}
                            >Out for delivery</Button>
                            }
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <b>Deliver to:</b> {order.userDetails.name?order.userDetails.name:'الاسم غير محدد'}
                        </Col>
                        <Col>
                        <FaPhone/> {order.userDetails.mobile}
                        </Col>
                        <Col >
                            <div style={{float:'right'}}>
                                <FaLocationArrow/> {order.userDetails.address}
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col>
                            <b>Delivery time:</b> { order.delivery_hour}
                        </Col>
                    </Row>
                    { order.notes !== null && order.notes!="" &&
                    <Row>
                        <Col>
                            <b>Notes:</b> { order.notes}
                        </Col>
                    </Row>
                    }
                </Card.Header>
                <Card.Body>
                    <Card.Title>Cart details:</Card.Title>
                        <Row>
                            <Col>Name</Col>
                            <Col>Type</Col>
                            <Col>Amount</Col>
                            <Col>Price( after discount)</Col>
                        </Row>
                        <hr
                            
                        />
                        {order.items.map(item=>
                        <Row key={item.id}>
                            <Col>{item.name_en} </Col>
                            <Col>{item.sold_by} {item.sold_by==='weight'? '('+item.unit_weight_in_grams+'gm)':''}</Col>
                            <Col>{item.amount} </Col>
                            <Col>{item.had_active_discount? item.price_after_discount* item.amount:item.price* item.amount} EGP</Col>

                        </Row>
                        )}
                        <hr></hr>
                        <Row>
                            <Col sm={9}></Col>
                            <Col>Total: {order.total_price}</Col>
                        </Row>
                </Card.Body>
            </Card>
        </div>
   )
}
export default Order
