import React, { useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function DeleteMyAccount() {
  const [formId, setFormId] = useState(1)
  const [mobileNumber, setMobileNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [success, setSuccess] = useState(false)
  const requestOtp = ()=>{
    console.log(mobileNumber)
    fetch(process.env.REACT_APP_API_KEY+'/requestPin',{
      method:'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'mobile': mobileNumber
      })
    }).then(response => {
      if (response.ok){
        return response.json()
      } else{
        console.log('Sending Otp failed.')
        return {}
      }
    }).then(data=>{
      if(data.status == 'success')
        setFormId(2)
    })
    .catch(err=> console.log(err)) 
  }
  const verifyOtp = ()=>{
    fetch(process.env.REACT_APP_API_KEY+'/verifyOtp',{
      method:'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'mobile': mobileNumber,
        'otp': otp
      })
    }).then(response => {
      if (response.ok){
        return response.json()
      } else{
        console.log('Otp verification failed.')
        return {}
      }
    }).then(data=>{
      if(data.id)
        deleteAcc(data.id)
    })
    .catch(err=> console.log(err)) 
  }
  const deleteAcc = (id)=>{
    fetch(process.env.REACT_APP_API_KEY+'/deleteUserData',{
      method:'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({
        'id': id,
      })
    }).then(response => {
      if (response.ok){
        
        return response.json()
      } else{
        console.log('deleting user details failed.')
        return {}
      }
    }).then(data=>{
      if(data.status=='success'){
        setFormId(0)
        setSuccess(true)
      }
    })
    .catch(err=> console.log(err)) 
  }
  return (
    <div id="DeleteMyAccount" style={{textAlign:'center'}}>
      <h1>Taswi2a Account Deletion</h1>
      {formId == 1 &&
      <Form>
        <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={mobileNumber} onChange={(e)=>{
                console.log(e.target.value)
                setMobileNumber(e.target.value) }
               } />
            </Form.Group>
          </Col>
          <Col sm={4}></Col>
        </Row>
        <Button variant="primary" onClick={requestOtp}>Request OTP</Button>
      </Form>
      }
      {formId == 2 &&
      <Form>
        <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Verify OTP</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={otp} onChange={(e)=>{
                console.log(e.target.value)
                setOtp(e.target.value) }
               } />
            </Form.Group>
          </Col>
          <Col sm={4}></Col>
        </Row>
        <Button variant="danger" onClick={verifyOtp}>Verify And Delete </Button>
      </Form>
      }
      {success &&
      <p> Your account data has been completely deleted. </p>
      
      }
    </div>
  );
}

export default DeleteMyAccount;
