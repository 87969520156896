import React, {useState, useEffect} from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FaBell } from "react-icons/fa"
import logo from '../assets/images/logo.jpeg';
import StockManager from './StockManager'
import Orders from './Orders'
import AvailabilityManager from './AvailabilityManager'
import CategoriesManager from './CategoriesManager'
import DealsManager from'./DealsManager'
import Users from './Users'
import DeliveryAreas from './DeliveryAreas'
import Analysis from './Analysis'
import { useHistory } from "react-router-dom";
import {getNotifications} from '../Api'

const Dashboard = ()=>{
    const history = useHistory();
    const [notificationsCount, setNotificationsCount] = useState('')
    const [refreshNotifications, setRefreshNotifications] = useState(true)
    const [activeKey, setActiveKey] = useState('orders')
    useEffect(()=>{
        if(localStorage.getItem('user_token')==null){
           //redirect to login
           history.push("/");
        }
        // get notifications every 30 secs
        let refreshNotificationsTimer = setInterval(() => setRefreshNotifications(true), 500*1000)
        return () => {
            clearTimeout(refreshNotificationsTimer)
        };
    },[])
    useEffect(()=>{
        console.log('refreshing notif')
        if( refreshNotifications){
            getNotifications().then((res)=>{
                if(res.status=="success"){
                    setNotificationsCount(res.count)
                    setRefreshNotifications(false)
                }
            
            })
        }
    },[refreshNotifications])
    function viewNotifications(){
        // changes the tab to orders and selects pending
        document.getElementById('ordersTabLink').firstChild.click();    }
    function logout(){
        localStorage.removeItem('user_token')
        history.push("/");
    }
    return(
        <Tab.Container id="left-tabs-example"  defaultActiveKey='orders'>
            <Row>
                <Col sm={2} style={{height:'100vh',position:'fixed'}} className="shadow-sm p-3 mb-5 bg-white rounded">
                    <Row style={{marginBottom:'20px', backgroundColor:'white'}}>
                        <Col xs={3}>
                            <img src={logo} style={{width:'100%'}}></img>
                        </Col>
                        <Col style={{display:'flex',alignItems:'center',justifyContent:'left'}}>
                            <h6>Tawsi2a Dashboard</h6>
                        </Col>
                    </Row>
                    
                    <Nav variant="pills" className="flex-column" style={{cursor:'pointer'}}>
                        <Nav.Item id="ordersTabLink"><Nav.Link eventKey="orders"  >Orders</Nav.Link></Nav.Item>
                        <Nav.Item ><Nav.Link eventKey="availability">Avilability Manager</Nav.Link></Nav.Item>
                        {localStorage.getItem('admin_type')!=='admin' &&
                        <React.Fragment>
                            <Nav.Item><Nav.Link eventKey="stock">Stock Manager</Nav.Link></Nav.Item>
                        </ React.Fragment> 
                        }
                        {localStorage.getItem('admin_type')=='superadmin' &&
                        
                            
                        <React.Fragment>
                            {/* super admin only  */}
                            <Nav.Item><Nav.Link eventKey="deals">Deals Manager</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="categories">Categories Manager</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="users">Users</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="deliveryAreas">Delivery Areas</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="analysis">Advanced analysis</Nav.Link></Nav.Item>
                        </React.Fragment>
                        }

                    </Nav>
                </Col>
                <Col sm={10} style={{ marginLeft:'16.6666666%'}}>
                    <Tab.Content >
                        <div id="topRightControls"></div>
                        <Button size="sm" className='logoutButton' variant='danger' onClick={()=>{logout()}}>Log out</Button>
                        <div id="notificationsContainer" onClick={()=>{viewNotifications()}} style={{cursor:'pointer'}}>
                            <div style={{position:'relative'}} >
                                <FaBell style={{'fontSize':'18px'}}/>
                                <div id="notificationCount"> {notificationsCount}</div>
                            </div>
                        </div>
                        <Tab.Pane eventKey="orders">
                            <Orders></Orders>
                        </Tab.Pane>
                        <Tab.Pane eventKey="availability">
                            <AvailabilityManager></AvailabilityManager>
                        </Tab.Pane>
                        <Tab.Pane eventKey="stock">
                            <StockManager></StockManager>
                        </Tab.Pane>
                        <Tab.Pane eventKey="deals">
                            <DealsManager></DealsManager>
                        </Tab.Pane>
                        <Tab.Pane eventKey="categories">
                            <CategoriesManager></CategoriesManager>
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            <Users />
                        </Tab.Pane>
                        <Tab.Pane eventKey="deliveryAreas">
                            <DeliveryAreas />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analysis">
                            <Analysis />
                        </Tab.Pane>
                        
                    </Tab.Content>
                </Col>

            </Row>
            
        </Tab.Container>
    )}
export default Dashboard
