import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {editDeliveryArea,addNewDeliveryArea} from '../Api'


const EditDeliveryAreaModal = (props)=>{
    const [nameEn, setNameEn] = useState('')
    const [nameAr, setNameAr] = useState('')
    const [price, setPrice] = useState('')
   

    useEffect(()=>{
        if(props.mode=='edit'){
            setNameEn(props.areaDetails.name_en)
            setNameAr(props.areaDetails.name_ar)
            setPrice(props.areaDetails.delivery_price)
        }


    },[])
    
    function submit(){
        
        let deliveryAreaDetails = {
            name_en: nameEn,
            name_ar: nameAr,
            delivery_price: price,
           
        }
        // add the id if in edit mode
        if(props.mode=='edit')
            deliveryAreaDetails['id']=props.areaDetails.id
        
        if (props.mode=='edit')
        editDeliveryArea(deliveryAreaDetails).then(res=>{
            if(res.status=='success'){
                props.displayMessage(res.status,'Delivery area details updated successfully.')
                props.refreshDeliveryAreas('refresh'+Math.random())
            }
        })
        else
            addNewDeliveryArea(deliveryAreaDetails).then(res=>{
                if(res.status=='success'){
                    props.displayMessage(res.status,'Delivery area details updated successfully.')
                    props.refreshDeliveryAreas('refresh'+Math.random())
                }
            })
    }
    return(
        <Form>
            <Row>
                <Col>
                {/* name in english*/}
                    <FloatingLabel
                        controlId="nameEn"
                        label="Delivery area name in English"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={nameEn} onChange={(e)=>{setNameEn(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* name in arabic*/}
                    <FloatingLabel
                        controlId="nameAr"
                        label="Delivery area name in Arabic"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={nameAr} onChange={(e)=>{setNameAr(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* delivery price*/}
                    <FloatingLabel
                        controlId="price"
                        label="Delivery price"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={price} onChange={(e)=>{setPrice(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Button variant="primary" size='sm' onClick={submit}>
                Save Changes
            </Button>
        </Form>
    )
}
export default EditDeliveryAreaModal