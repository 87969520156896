import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {editCustomer} from '../Api'


const EditUserModal = (props)=>{
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [mobile2, setMobile2] = useState('')
    const [email, setEmail] = useState('')

    useEffect(()=>{
        console.log(props.userDetails)
        setName(props.userDetails.name)
        setAddress(props.userDetails.address)
        setAddress2(props.userDetails.address2)
        setMobile2(props.userDetails.mobile2)
        setEmail(props.userDetails.email)

    },[])
    
    function submit(){
        
        let cust = {
            id: props.userDetails.id,
            name: name,
            address: address,
            address2: address2,
            mobile2: mobile2,
            email: email
        }
        editCustomer(cust).then(res=>{
            if(res.status=='success'){
                props.displayMessage(res.status,'User details updated successfully.')
                props.refreshUsers('refresh'+Math.random())
            }
        })
    }
    return(
        <Form>
            <Row>
                <Col>
                {/* name */}
                    <FloatingLabel
                        controlId="name"
                        label="Customer full name"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={name} onChange={(e)=>{setName(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* address */}
                    <FloatingLabel
                        controlId="address"
                        label="Customer Address"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* address 2*/}
                    <FloatingLabel
                        controlId="address2"
                        label="Customer 2nd Address"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={address2} onChange={(e)=>{setAddress2(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* 2nd mobile number */}
                    <FloatingLabel
                        controlId="mobile2"
                        label="Customer 2nd mobile number"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={mobile2} onChange={(e)=>{setMobile2(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                {/* email */}
                    <FloatingLabel
                        controlId="email"
                        label="Customer Email"
                        className="mb-3"
                    >
                        <Form.Control size="sm" type="text" defaultValue={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Button variant="primary" size='sm' onClick={submit}>
                Save Changes
            </Button>
        </Form>
    )
}
export default EditUserModal