import React, { useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import DeleteMyAccount from "./components/deleteMyAccount";
function App() {
  useEffect(() => {
    document.title = "Taswi2a Dashboard"
 }, []);
  return (
    
    <Router>
    <div className='container-fluid'>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
      <Route path="/deleteMyAccount">
          <DeleteMyAccount></DeleteMyAccount>
        </Route>
        <Route path="/dashboard">
          <Dashboard></Dashboard>
        </Route>
        <Route path="/">
         <Login></Login>
        </Route>
       
      </Switch>
    </div>
  </Router>
);

}

export default App;
