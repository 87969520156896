import React, {useState, useEffect, useRef, useMemo} from 'react'
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'
import debounce from 'lodash.debounce';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Item from './Item'
import Alert from 'react-bootstrap/Alert'
import { FaPlus } from "react-icons/fa"
import { FaSort } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaTimes } from "react-icons/fa"
import { FaCheck } from "react-icons/fa"
import { useHistory } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import {getAllSubcategories, getCategoryItems, adminSearch, sortSubCategoryItems} from '../Api'
import AddNewItem from './AddNewItem'
const StockManager = ()=>{
    const history = useHistory();
    const [subcategories,setSubcategories] = useState([])
    const [ready,setReady] = useState(false)
    const [newItemModal, setNewItemModal] = useState(false);
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    const [refreshCategoryItems, setRefreshCategoryItems] =useState('')
    const [selectedCategoryId, setSelectedCategoryId] = useState()
    const [items, setItems]= useState([])
    const [filteredItems, setFilteredItems]= useState([])
    const [searchString, setSearchString] = useState('')
    const [mode, setMode]=useState('edit')
    const [itemsBeforeSorting, setItemsBeforeSorting] = useState([])
    const [showDiscardConfirmationBox, setShowDiscardConfirmationBox] =useState(false)
    const refs= useRef([])
    const searchInputRef= useRef('')

  
    useEffect(()=>{
        getAllSubcategories().then(response=>{
            if(response.status==='failure'){
                localStorage.removeItem('user_token')
                history.push("/");
            }else{
                setSubcategories(response.data)
                setReady(true)
            }
        })
        return () => {
            debouncedResults.cancel();
          };
    },[])
    useEffect(()=>{
        if(alertMessageStatus==='success'){
            // close the modal on success
            setNewItemModal(false)
        }
    },[alertMessageStatus])
    useEffect(()=>{
        if(selectedCategoryId){
            searchInputRef.current.value=""
            setSearchString("")
            getCategoryItems(selectedCategoryId).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        }
    },[selectedCategoryId])
    
    useEffect(()=>{
        if(selectedCategoryId){
            getCategoryItems(selectedCategoryId).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        }
    },[refreshCategoryItems])
    useEffect(()=>{
        if(searchString.length>2){
            adminSearch(searchString).then(res=>{
                if(res.status=="failure"){
                    localStorage.removeItem('user_token')
                    history.push("/");
                }
                else{
                    setItems(res.data)
                }
                
            })
        } else if(!searchString){
            // go back to state before search .. subcategory items 
            // only if a subcategory is selected
            if(selectedCategoryId){
                setRefreshCategoryItems('refresh'+Math.random())
            } else{
                // just clear items array
                setItems([])
            }
           
        }
    },[searchString])
    const handleSearchStringChange=(e)=>{
        setSearchString(e.target.value)
    }
    const debouncedResults = useMemo(() => {
        return debounce(handleSearchStringChange, 300);
      }, [])
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    const onSortEnd = (oldIndex, newIndex) => {
        setItems((array) => arrayMove(array, oldIndex, newIndex));
      };
    function saveSorting(){
        setMode('edit')
        let sortedItems=[]
        items.forEach(item=>{
            sortedItems.push(item.id)
        })
        sortSubCategoryItems({items:sortedItems}).then(res=>{
            if(res.status=='success'){
                setAlertMessageStatus('success')
                setAlertMessageText('Sorting saved successfully.')
                setAlertMessageDisplay(true)
            } else{
                setAlertMessageStatus('danger')
                setAlertMessageText('Sorting saved failed. Something went wrong.')
                setAlertMessageDisplay(true)
            }
        })
    }
    function discardSortingChanges(){
        setItems(itemsBeforeSorting)
        setMode('edit')
    }
    return ready?
    (
        <div>
           
            {alertMessageDisplay===true &&
            <Collapse in={alertMessageDisplay} appear={true} >
                <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
                    style={{
                        position:'fixed',
                        top: '0px',
                        left: '15%', 
                        width: '100%',
                        zIndex:9999, 
                        borderRadius:'0px',
                        width:'70%',
                    }}
                    onClose={() => setAlertMessageDisplay(false)}>
                    <p>{alertMessageText}</p>
                </Alert>
            </Collapse>
            }
             {/* header */}
            <Row style={{padding:'20px', borderBottom:'1px solid #f0f2f0', backgroundColor:'#edf0ef', paddingRight:'40px'}} >
               
                <Col sm={3}>
                {mode!=='sort'&&
                    <FloatingLabel
                        controlId="Category"
                        label="Category"
                        className="mb-3"
                    >
                        {subcategories &&
                        <Form.Select  onChange={(e)=>{ 
                            setSelectedCategoryId(e.target.value)
                            
                        }}
                            value={undefined}>
                            <option>Choose a category</option>

                        {subcategories.map(sub=>
                            <option value={sub.id} key={sub.id}>{sub.name_en}</option>
                            )}
                            
                        </Form.Select>
                        }
                    </FloatingLabel>
                }
                </Col>

                <Col sm={3} style={{display:'flex',alignItems:'baseline',justifyContent:'left'}}>
                    {mode!=="sort"&&
                    <InputGroup   size="sm">
                        <InputGroup.Text id="basic-addon1"><FaSearch/></InputGroup.Text>
                        <input
                        placeholder="Search items"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={debouncedResults}
                        ref={searchInputRef}
                        style={{padding:'5px', border:'1px solid lightgray'}}
                        />
                    </InputGroup>
                    }
                </Col>
                <Col sm ={2}>
                {mode!=='sort'&&
                    <Button variant="success" size="sm" style={{margin:'3px'}} onClick={()=>{setNewItemModal(true)}}>
                        <FaPlus style={{fontSize:12}}/> New item
                    </Button>
                }
                </Col>
                <Col sm={4} >
                    {mode=='sort'?
                    <div>
                         <Button variant="success" size="sm" style={{minWidth:'60px',margin:'3px'}} onClick={()=>{saveSorting()}}>
                            <FaCheck style={{fontSize:12}}/> Save 
                        </Button>
                        <Button variant="danger" size="sm" style={{minWidth:'60px',margin:'3px'}} onClick={()=>{setShowDiscardConfirmationBox(true)}}>
                            <FaTimes style={{fontSize:12}}/> Discard
                        </Button>
                    </div>
                    :
                    <React.Fragment>
                        {searchString =='' && selectedCategoryId &&
                        <Button variant="primary" size="sm" style={{minWidth:'60px',margin:'3px'}} onClick={()=>{
                            setMode('sort');
                            setItemsBeforeSorting(items)
                            }}>
                            <FaSort/> Sort items
                        </Button>
                        }
                    </React.Fragment>
                   
                }
                </Col>
                
            </Row>
            {/* sort mode */}
            { mode=='sort'?
            <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
            <div className=" d-flex flex-wrap">
            {items.map((item,idx) => (
                <SortableItem key={item.id} style="cursor: grab">
                    <Col sm={4}>
                        <Item 
                            ref={refs[idx]}
                            mode={mode}
                            data={item} 
                            subcategories={subcategories} 
                            displayMessage={displayMessage}
                            refreshItems={setRefreshCategoryItems}
                        ></Item>
                    </Col>
                </SortableItem>
            ))}
                </div>
            </SortableList>
            : mode =='edit'?
            // normal mode .. edit
            <div className=" d-flex flex-wrap">
            {items.map((item,idx)=>{
                return <Col sm={4} key={item.id}>
                    <Item 
                        data={item} 
                        subcategories={subcategories} 
                        displayMessage={displayMessage}
                        refreshItems={setRefreshCategoryItems}
                    ></Item>
                </Col>
            })}
            </div> 
            :<div></div>
            }
            {/* new item modal */}
            <Modal show={newItemModal} onHide={()=>{setNewItemModal(false)}} size="lg">
                <Modal.Header closeButton>
                <Modal.Title >Add a new item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewItem refreshItems={setRefreshCategoryItems} subcategories={subcategories}
                    displayMessage={displayMessage}></AddNewItem>
                </Modal.Body>
                
                
            </Modal>
            {/* discard sorting changes confirmation modal */}
            <Modal show={showDiscardConfirmationBox} onHide={()=>{setShowDiscardConfirmationBox(false)}} size="md">
                <Modal.Header closeButton>
                <Modal.Title >Discard sorting changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to discard sorting changes? All changes will be reverted.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> setShowDiscardConfirmationBox(false)}>Cancel</Button>
                    <Button variant="danger" onClick={()=>{
                        discardSortingChanges();
                        setShowDiscardConfirmationBox(false)
                    }}>Discard</Button>
                </Modal.Footer>
                
            </Modal>
        </div>
    )
    : <div></div>
}
export default StockManager