async function login(data){
  var responseStatus= await fetch(process.env.REACT_APP_API_KEY+'/adminLogin', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) 
  })
  .then(res => res.json())
  .then((res)=>{
    if(res.status==='success'){
        localStorage.setItem('user_token',res.token)
        localStorage.setItem('admin_type',res.token.split('/')[0])
    }
    return res
  })
  return responseStatus
}
async function getNotifications(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/notifications', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function getDeliveryAreas(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/deliveryAreas', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function editDeliveryArea(data){

  var response= await fetch(process.env.REACT_APP_API_KEY+'/deliveryAreas/update', {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('user_token')

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return response
}
async function addNewDeliveryArea(data){

  var response= await fetch(process.env.REACT_APP_API_KEY+'/deliveryAreas/create', {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('user_token')

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return response
}
async function deleteDeliveryArea(id){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/deliveryAreas/delete/'+id, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')

      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function addNewItem(data){

  var responseStatus= await fetch(process.env.REACT_APP_API_KEY+'/item/create', {
    method: 'POST',
    body: data,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) 
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return responseStatus
}
async function addNewSubcategory(data){

  var responseStatus= await fetch(process.env.REACT_APP_API_KEY+'/createSubCategory', {
    method: 'POST',
    body: data,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) 
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return responseStatus
}
async function updateItem(data){

  var responseStatus= await fetch(process.env.REACT_APP_API_KEY+'/item/update', {
    method: 'POST',
    body: data,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      // 'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return responseStatus
}
async function updateSubcategory(data){

  var responseStatus= await fetch(process.env.REACT_APP_API_KEY+'/updateSubcategory', {
    method: 'POST',
    body: data, 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      // 'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return responseStatus
}
async function getAllSubcategories(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/adminAllSubCategories', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function getAllCategories(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/categories', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    // console.log(jsonResponse.data)
    return jsonResponse.data
}
async function getSubcategories(categoryId){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/category/'+categoryId, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    // console.log(jsonResponse.data)
    return jsonResponse.data
}
async function getCategoryItems(categoryId){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/adminCategoryItems/'+categoryId, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function deleteItem(id){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/item/delete/'+id, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function deleteSubcategory(id){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/deleteSubcategory/'+id, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function getOrders(dateRange,status = 'pending'){
  // format date
  //dateRange[0] = dateRange[0].toISOString()
  // dateRange[1] = dateRange[1].toISOString()


  var response = await fetch(process.env.REACT_APP_API_KEY+'/getOrders?' + new URLSearchParams({
      status: status,
      startDate: dateRange[0].toISOString().slice(0, 19).replace('T', ' '),
      endDate: dateRange[1].toISOString().slice(0, 19).replace('T', ' '),
    }),{
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function changeOrderStatus(id,status){
  
  var response = await fetch(process.env.REACT_APP_API_KEY+'/changeOrderStatus/'+id+'/'+status, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    // console.log(jsonResponse)
    return jsonResponse
}
async function updateItemAvailability(id,availability){
  
  var response = await fetch(process.env.REACT_APP_API_KEY+'/updateItemAvailability?id='+id+'&availability='+availability, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    // console.log(jsonResponse)
    return jsonResponse
}
async function getCustomers(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/getCustomers', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    // console.log(jsonResponse)
    return jsonResponse
}
async function editCustomer(data){

  var response= await fetch(process.env.REACT_APP_API_KEY+'/editCustomer', {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('user_token')

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return response
}
async function adminSearch(searchString){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/adminSearch/'+searchString, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function sortSubCategoryItems(data){
  var response= await fetch(process.env.REACT_APP_API_KEY+'/sortSubCategoryItems', {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('user_token')

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return response
}
async function getAllDeals(){

  var response = await fetch(process.env.REACT_APP_API_KEY+'/adminAllDeals', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('user_token')
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    let jsonResponse= await response.json()
    return jsonResponse
}
async function sortDeals(data){
  var response= await fetch(process.env.REACT_APP_API_KEY+'/sortDeals', {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('user_token')

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
  .then(res => res.json())
  .then((res)=>{
    return res
  })
  return response
}
export {login, getNotifications,getAllSubcategories, addNewItem, getCategoryItems, updateItem, addNewSubcategory,
   deleteItem, getOrders, changeOrderStatus,updateItemAvailability, getSubcategories, sortSubCategoryItems,
    updateSubcategory, getAllCategories, deleteSubcategory,getCustomers, editCustomer, adminSearch, 
    getDeliveryAreas, addNewDeliveryArea, editDeliveryArea, deleteDeliveryArea, getAllDeals, sortDeals}