import React, {useState, useEffect} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Subcategory from './Subcategory'
import Alert from 'react-bootstrap/Alert'
import { FaPlus } from "react-icons/fa"
import { useHistory } from "react-router-dom";

import { getAllCategories, getSubcategories} from '../Api'
import AddNewSubcategory from './AddNewSubcategory'
const CategoriesManager = ()=>{
    const history = useHistory();
    const [categories,setCategories] = useState([])
    const [ready, setReady] = useState(false)
    const [subcategories,setSubcategories] = useState([])
    const [newSubcategoryModal, setNewSubcategoryModal] = useState(false)
    const [alertMessageDisplay, setAlertMessageDisplay]= useState(false)
    const [alertMessageStatus, setAlertMessageStatus]= useState()
    const [alertMessageText, setAlertMessageText]= useState()
    const [refreshSubcategories, setRefreshSubcategories] =useState('')
    const [selectedCategoryId, setSelectedCategoryId] = useState()
    useEffect(()=>{
    
        getAllCategories().then(response=>{
            if(response.status==='failure'){
                localStorage.removeItem('user_token')
                history.push("/");
            }else{
                setReady(true)
                setCategories(response)
            }
        })

    },[])
    useEffect(()=>{
        if(alertMessageStatus==='success'){
            // close the modal on success
            setNewSubcategoryModal(false)
        }
    },[alertMessageStatus])
    useEffect(()=>{
        if(selectedCategoryId){
            getSubcategories(selectedCategoryId).then(subcategories=>{
                setSubcategories(subcategories)
            })
        }
    },[selectedCategoryId])
    function displayMessage(status,message){
        setAlertMessageDisplay(true);
        setAlertMessageStatus(status)
        setAlertMessageText(message)
        setTimeout(() => {
            setAlertMessageDisplay(false)
            setAlertMessageStatus('')
            setAlertMessageText('')
        }, 2000);
    }
    useEffect(()=>{
        if(selectedCategoryId){
            getSubcategories(selectedCategoryId).then(subcategories=>{
                setSubcategories(subcategories)
            })
        }
    },[refreshSubcategories])
    return ready?(
        <div>
            {/* header */}
            {alertMessageDisplay===true &&
                <Alert variant={alertMessageStatus==='success'? 'success':'danger'} dismissible
                    style={{
                        position:'fixed',
                        top: '0px',
                        left: '15%', 
                        width: '100%',
                        zIndex:9999, 
                        borderRadius:'0px',
                        width:'70%',
                    }}
                    onClose={() => setAlertMessageDisplay(false)}>
                    <p>{alertMessageText}</p>
                </Alert>
            }
            <Row style={{padding:'20px', borderBottom:'1px solid #f0f2f0', backgroundColor:'#edf0ef', paddingRight:'40px'}} >
                <Col sm={2} style={{display:'flex',alignItems:'center',justifyContent:'left'}}>
                    <h6> Categories Manager </h6>
                </Col>
                <Col sm={4}>
                <FloatingLabel
                    controlId="Category"
                    label="Category"
                    className="mb-3"
                >   
                    {categories && 
                    <Form.Select  onChange={(e)=>{ 
                        setSelectedCategoryId(e.target.value)
                    }}
                        value={undefined}>
                        <option>Choose a category</option>

                    {categories.map(sub=>
                        <option value={sub.id} key={sub.id}>{sub.name_en}</option>
                        )}
                        
                    </Form.Select>
                    }
                </FloatingLabel>

                </Col>

                {/* <Col sm={4} style={{display:'flex',alignItems:'baseline',justifyContent:'left'}}>
                    <InputGroup   size="sm">
                        <InputGroup.Text id="basic-addon1"><FaSearch/></InputGroup.Text>
                        <FormControl
                        placeholder="Search items"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </Col> */}
                <Col>
                    <Button variant="success" size="sm" onClick={()=>{setNewSubcategoryModal(true)}}>
                        <FaPlus style={{fontSize:12}}/> New Subcategory
                    </Button>
                </Col>
            </Row>
            <div className=" d-flex flex-wrap">
            {subcategories.map((subcategory,idx)=>{
                return <Col sm={4} key= {subcategory.id}> 
                    <Subcategory key={subcategory.id}
                        data={subcategory} 
                        categories={categories} 
                        displayMessage={displayMessage}
                        refreshSubcategories={setRefreshSubcategories}
                    ></Subcategory>
                </Col>
            })}
            </div>
            <Modal show={newSubcategoryModal} onHide={()=>{setNewSubcategoryModal(false)}} size="lg">
                <Modal.Header closeButton>
                <Modal.Title >Add a new subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewSubcategory refreshSubcategories={setRefreshSubcategories} categories={categories}
                    displayMessage={displayMessage}></AddNewSubcategory>
                </Modal.Body>
                
                
            </Modal>
        </div>
    )
    : <div></div>
}
export default CategoriesManager