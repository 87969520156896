
export const formatDate = function (date){
    // reformat the database date into JS date
    date= date.replace('T',' ').replace('Z','')
    var tokens = date.split(/[- :]/);
    var dateObj = new Date(Date.UTC(tokens[0], tokens[1]-1, tokens[2], tokens[3], tokens[4], tokens[5]));
    // get the name of the day
    var options={weekday:'long'}
    var dayName= new Intl.DateTimeFormat('en-US',options).format(dateObj)
    var dayDate= new Intl.DateTimeFormat('en-US').format(dateObj)
    // format
    return dayName+' '+dayDate
}
export const formatTime = function (date){
    // reformat the database date into JS date
    date= date.replace('T',' ').replace('Z','')
    var tokens = date.split(/[- :]/);
    var dateObj = new Date(Date.UTC(tokens[0], tokens[1]-1, tokens[2], tokens[3], tokens[4], tokens[5]));
    var hour= tokens[3]
    var minutes= dateObj.getMinutes()
    var amOrPm= hour>11? 'PM':'AM'
    if(hour>11) {hour-=12}
    
    return hour+':'+minutes+' '+amOrPm
}
export const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
    })