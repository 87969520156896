import React, {useState, useEffect, useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Alert from 'react-bootstrap/Alert'
import { IconContext } from "react-icons";
import { BsFillSquareFill } from "react-icons/bs"
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'


import Order from './Order'
import { getOrders,changeOrderStatus} from '../Api'
const Orders = ()=>{
    const refreshEveryHowManySeconds=60
    const [selectedStatus, setSelectedStatus]= useState('pending')
    const [orderToCancelId, setOrderToCancelId] =useState('')
    const [showConfirmCancellationModal,setShowConfirmCancellationModal] =useState(false)
    const [refreshDisplayingOrders,setRefreshDisplayingOrders]= useState('')
    const [showSuccessAlert,setShowSuccessAlert]= useState(false)
    const [orders, setOrders] = useState([])
    // today's date
    const [dateRange, setDateRange] = useState([new Date(),new Date()])
    useEffect(()=>{
        const intervalId = setInterval(() => {
            setRefreshDisplayingOrders('refresh'+Math.random())
          }, refreshEveryHowManySeconds*1000);
          return () => clearInterval(intervalId);
    },[])
    useEffect(()=>{
        
        getOrders(dateRange, selectedStatus).then(response=>{
            if(response.status=='success'){
                setOrders(response.orders)
            }
        })
    },[selectedStatus,refreshDisplayingOrders])
    useEffect(()=>{
        dateRange[0].setHours(0,0,0)
        dateRange[1].setHours(23,59,59)
        getOrders(dateRange).then(response=>{
            if(response.status=='success'){
                setOrders(response.orders)
            }
        })
    },[dateRange])
    function updateOrderStatus(orderId,status){
        changeOrderStatus(orderId,status).then(response=>{
            //validate failure
            console.log(response)
            // show success alert
            setShowSuccessAlert(true)
            setRefreshDisplayingOrders('refresh'+Math.random())
        })
    }
    return (<div>
        <h2 className='m-2' style={{textAlign:'center'}}>Orders Manager</h2>
        <div style={{width:'60%', marginLeft:'20%', textAlign:'center'}}>
            <div className="m-3">
                <ButtonGroup aria-label="Basic example" >
                    <Button onClick={()=>{setSelectedStatus('all')}} variant={selectedStatus==='all'? 'secondary' :"outline-secondary"}>
                         All
                    </Button>
                    <Button onClick={()=>{setSelectedStatus('pending')}} variant={selectedStatus==='pending'? 'secondary' :"outline-secondary"}>
                        <IconContext.Provider value={{ color: "yellow" }}>
                            <BsFillSquareFill/>
                        </IconContext.Provider> Pending
                    </Button>
                    <Button onClick={()=>{setSelectedStatus('outForDelivery')}} variant={selectedStatus==='outForDelivery'? 'secondary' :"outline-secondary"}>
                        <IconContext.Provider value={{ color: "blue" }}>
                            <BsFillSquareFill/>
                        </IconContext.Provider> Out for delivery </Button>
                    <Button onClick={()=>{setSelectedStatus('completed')}} variant={selectedStatus==='completed'? 'secondary' :"outline-secondary"}>
                        <IconContext.Provider value={{ color: "Green" }}>
                        <BsFillSquareFill/>
                    </IconContext.Provider> Complete</Button>
                    <Button onClick={()=>{setSelectedStatus('cancelled')}} variant={selectedStatus==='cancelled'? 'secondary' :"outline-secondary"}>
                        <IconContext.Provider value={{ color: "red" }}>
                        <BsFillSquareFill/>
                    </IconContext.Provider> Cancelled</Button>
                </ButtonGroup> 
            </div>
        </div>
        <div style={{width:'60%', marginLeft:'20%', textAlign:'center', marginBottom:'15px'}}>
        <DateRangePicker 
        appearance="default" 
        value={dateRange}
        onOk={(range)=>{ setDateRange(range)}}
        />
        </div>
        <Alert dismissible
            variant='success' show={showSuccessAlert} 
            onClose={() => setShowSuccessAlert(false)}
            >
            Order status changed successfully
        </Alert>
        <Modal show={showConfirmCancellationModal} onHide={()=>{setShowConfirmCancellationModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel confimation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to cancel order #{orderToCancelId}?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" 
                    onClick={()=>{setShowConfirmCancellationModal(false)}}>
                        Do nothing
                </Button>
                <Button variant="Danger" 
                onClick={()=>{
                        updateOrderStatus(orderToCancelId,'cancelled')
                        setShowConfirmCancellationModal(false)
                    }} >
                    Cancel order</Button>
            </Modal.Footer>
        </Modal>
        {orders.length>0 ?
        <div >
            {orders.map((order, index)=>
                <Order order={order}
                    index={index}
                    key={order.id}
                    confirmDeletion={setShowConfirmCancellationModal}
                    orderToDeleteId={setOrderToCancelId}
                    successNotify={setShowSuccessAlert}
                    refreshDisplaying={setRefreshDisplayingOrders}
                >
                </Order>
                
            )}
        </div>
        :
        <p style={{textAlign:'center'}}> There are no orders in the selected filters</p>
        }        
    </div>)
}
export default Orders